import {withStyles} from "@material-ui/core/styles";
import {Body2} from "./type";
import React from "react";
import {TwoColContainer} from "./GridStuff";

const styles = theme => ({


});



export const ReferenceItem = withStyles(styles)(({overlineLabel, url, linkText, blurb, children, classes}) => {
    return (
        <div style={{
            marginBottom: 24,
            breakInside: 'avoid',
            pageBreakInside: 'avoid',
            webkitColumnBreakInside: 'avoid'
        }}>
            {/*{overlineLabel && <h4 className={classes.header4}>{overlineLabel}</h4>}*/}
            <Body2>
                {overlineLabel && <b>{overlineLabel}<br/></b>}

                <a href={url} dangerouslySetInnerHTML={{__html: linkText}}/> {blurb} {children}


            </Body2>
        </div>
    )
})
export const ReferenceList = withStyles(styles)(({referenceList, classes}) => {

    return (

        <TwoColContainer>
            {referenceList.map(([line1Text, line2Text, line3Text, url], j) => (
                <ReferenceItem overlineLabel={line1Text} linkText={line2Text} blurb={line3Text} url={url}
                               index={j}
                />
            ))
            }

        </TwoColContainer>


    )
})