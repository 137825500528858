import React, {useState} from 'react';
import {graphql, Link} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../components/layoutV2"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInnerV2";
import * as PATHS from "../utils/paths";
import "../utils/index"
import {Button} from "@material-ui/core";
import {
    GridColLeftContent,
    GridColMainContent,
    GridColQuarterContent,
    GridContainer
} from "../components/GridStuff";
import {Body1, Header3, Header4, Header7} from "../components/type";
import {PageHero} from "../components/PageHero";
import {ReferenceItem, ReferenceList} from "../components/ReferenceList";
import {SectionContainer} from "../components/SectionContainer";
import {SMALL_DOWN_MEDIA_QUERY, XS_DOWN_MEDIA_QUERY} from "../components/constants";

const styles = theme => ({
    root: {
        fontSize: 21,
        lineHeight: 1.48,
    },
    mica2024: {
        "& a": {
            color: "#0E0E0E",
            // textDecoration: "none",
        },

    },


    spacer: {
        height: 96,
    },
    spacerHalf: {
        height: 48,
    },
    spacerQuarter: {
        height: 24,
    },
    spacerEigth: {
        height: 12,
    },
    spacerSixteenth: {
        height: 6,
    },

    videoWrapper: {
        position: "relative",
        paddingBottom: "56.25%", /* 16:9 */
        paddingTop: 25,
        height: 0,
    },
    video: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    considerItem: {
        marginBottom: 48,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 36,
        },
    },
    hideXSDown: {

        [XS_DOWN_MEDIA_QUERY]: {
            display: 'none',
        },
    },
});



const ConsiderItem = withStyles(styles)(({line1, line2, url, classes}) => {
    return (
        <div className={classes.considerItem} style={{

        }}>
            <a href={url} style={{textDecoration: "none"}}>
                <Header7 style={{marginBottom: 6, color: "#4d4d4d", lineHeight: 1.08, fontWeight: 400}}>
                    {line1}
                </Header7>
                <Header7>
                    {line2}
                </Header7>
            </a>

        </div>
    )
})


const MAIN_CONTENT_ROW_GAP=6


const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={false} className={classes.mica2024}>
            <SEO title={"MICA Talk Materials"} location={{pathname: "/mica2024"}}
                 imagePath={"/images/3iap-mica-head-hor-v01.png"}/>

            <LayoutInner>

                <PageHero
                    imgURLHorizontal={"https://3iap.com/cdn/images/3iap-mica-head-hor-v01.png"}
                    imgURLVertical={"https://3iap.com/cdn/images/3iap-mica-head-vert-v01.png"}
                    imgAspectRatioVertical={1.5}
                    title={"Data is silly.<br/>People are too."}
                    subtitle={"Notes for MICA Data Analytics and Visual&shy;ization students toward more humanistic people visualizations."}>


                    <Body1>
                        Thank you for the chance to share how our social reality shapes our views of people, data,
                        and the world around us. Notes and materials are below for reference.
                        <br/><br/>
                        Please feel free to reach
                        out with questions!
                    </Body1>


                    <div style={{height: 24}}/>

                    <Link to={PATHS.CONTACT_PAGE} style={{textDecoration: "none"}}>
                        <Button variant={"contained"} color={"primary"}>Get In Touch</Button>
                    </Link>
                </PageHero>


                <div className={classes.spacer}/>


                <GridContainer>
                    <GridColLeftContent/>
                    <GridColMainContent>
                        <Header3>Considerations for <br className={classes.hideXSDown}/>People Visual&shy;ization</Header3>
                    </GridColMainContent>
                </GridContainer>

                <div className={classes.spacerHalf}/>
                <SectionContainer title={""} rowGap={48}>
                    <GridContainer style={{rowGap: 0}}>
                        <GridColQuarterContent style={{paddingBottom: 0}}>
                            {[
                                ["People are silly about data.", "It’s not what you show, it’s what people see.", "#sillydata"],
                                ["People are silly toward others.", "Blame systems, not people.", "#sillypeople"],
                                ["People are silly toward data about other people.", "Social bias is data bias.", "#sillypeopledata"],
                            ].map(([line1Text, line2Text, url]) => (
                                <ConsiderItem line1={line1Text} line2={line2Text} url={url}/>
                            ))

                            }
                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            {[
                                ["Silliness is serious.", "Visualizing reality shapes reality.", "#consequences"],
                                ["Silliness is within you.", "Data is a window. Data is a mirror. ", "#inkblot"],
                                ["Silliness is contagious.", "Showing social norms helps them spread.", "#conformity"],
                                ["Silliness is a design choice.", "Your work can help, or it can hurt.", "#responsibility"],
                            ].map(([line1Text, line2Text, url]) => (
                                <ConsiderItem line1={line1Text} line2={line2Text} url={url}/>
                            ))

                            }
                        </GridColQuarterContent>
                    </GridContainer>


                </SectionContainer>

                <div className={classes.spacer}/>


                <SectionContainer
                    id={"sillydata"}
                    overline={"People are silly about data."}
                    rowGap={MAIN_CONTENT_ROW_GAP}>
                    <GridContainer>

                        <GridColMainContent>
                            <Header4>
                                It’s not what you show, it’s what people see.
                            </Header4>
                            <div className={classes.spacerQuarter}/>

                            <Body1>
                                Remember there’s a gap between what viewers should conclude from the data, compared to
                                how it actually influences their beliefs. For example, correlation shouldn’t imply
                                causation, but it often does. Just because data has a halo of objectivity doesn't mean
                                that it's free from the power of suggestion.
                            </Body1>
                            <br/>

                            <ReferenceList referenceList={[
                                ["Heider & Simmel 1944", "An experimental study of apparent behavior.", null, "https://doi.org/10.2307/1416950"],
                                ["Xiong et al 2019", "Illusion of Causality in Visualized Data", null, "https://arxiv.org/abs/1908.00215"],
                                ["Wang et al 2024", "Causal Priors and Their Influence on Judgements of Causality in Visualized Data", null, "https://arxiv.org/abs/2408.16077"],
                                ["Matute et al 2015", "Illusions of causality: how they bias our everyday thinking and how they could be reduced", null, "https://doi.org/10.3389/fpsyg.2015.00888"],
                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer
                    overline={"People are silly about other people."}
                    id={"sillypeople"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Blame systems, not people.
                            </Header4>
                            <div className={classes.spacerQuarter}/>

                            <Body1>
                                When explaining other people's behaviors or outcomes, our first instincts are often biased toward personal blame.
                                Understanding the full story requires conscious effort to consider external influences.
                            </Body1>

                            <br/>

                            <ReferenceList referenceList={[
                                ["Howell & Shepperd 2011", "Demonstrating the Correspondence Bias", null, "https://doi.org/10.1177/0098628311421320"],
                                ["Jhangiani & Tarry 2022", "Biases in Attribution", null, "https://opentextbc.ca/socialpsychology/chapter/biases-in-attribution/"],
                                ["Cimpian & Salomon 2014", "The inherence heuristic: An intuitive means of making sense of the world, and a potential precursor to psychological essentialism", null, "https://doi.org/10.1017/s0140525x13002197"],
                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>


                <SectionContainer
                    overline={"People are silly about data about other people."}
                    id={"sillypeopledata"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Social bias is data bias.
                            </Header4>
                            <div className={classes.spacerQuarter}/>
                            <Body1>
                                Social cognitive biases can interfere with viewers' perceptions of dataviz.
                                For example, health disparity charts may be accurate and easy to read,
                                while still biasing viewers' causal explanations toward blaming the groups being visualized,
                                instead of external factors like social determinants of health.

                            </Body1>

                            <br/>

                            <ReferenceList referenceList={[
                                ["Jhangiani & Tarry 2022", "Biases in Attribution", null, "https://opentextbc.ca/socialpsychology/chapter/biases-in-attribution/"],
                                ["Davis & Museus 2019", "What Is Deficit Thinking? An Analysis of Conceptualizations of Deficit Thinking and Implications for Scholarly Research", null, "http://dx.doi.org/10.3998/currents.17387731.0001.110"],
                                ["Holder & Padilla 2024", "Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities", null, "https://osf.io/c28wg"],
                                ["Holder & Xiong 2022", "Dispersion vs Disparity: Hiding Variability Can Encourage Stereotyping When Visualizing Social Outcomes", null, "https://arxiv.org/abs/2208.04440"],
                                ["DP Council 2023", "The U.S. Playbook to Address Social Determinants of Health", null, "https://csph.d8.theopenscholar.com/files/systemsforaction/files/sdoh-wh-playbook.pdf"],
                                // ["XXX", "XXX", null, "XXX"],
                                // ["XXX", "XXX", null, "XXX"],
                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer
                    overline={"Silliness is serious."}
                    id={"consequences"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Showing reality shapes reality.
                            </Header4>
                            <div className={classes.spacerQuarter}/>

                            <Body1>
                                Social outcome disparity charts risk creating self-fulfilling prophecies.
                                Because social misbeliefs like victim blaming and harmful stereotypes play an active role
                                in perpetuating social inequality, charts that encourage these misbeliefs are also
                                indirectly influential in creating these harmful downstream consequences.
                            </Body1>

                            <br/>
                            <ReferenceList referenceList={[
                                ["Holder & Padilla 2024", "Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities", null, "https://osf.io/c28wg"],
                                ["Holder & Xiong 2022", "Dispersion vs Disparity: Hiding Variability Can Encourage Stereotyping When Visualizing Social Outcomes", null, "https://arxiv.org/abs/2208.04440"],
                                ["Cimpian & Salomon 2014", "The inherence heuristic: An intuitive means of making sense of the world, and a potential precursor to psychological essentialism", null, "https://doi.org/10.1017/s0140525x13002197"],
                                ["Kite & Whitley 2016", "Old-fashioned and contemporary forms of prejudice", null, "http://dx.doi.org/10.4324/9780367809218-5"],
                                ["Leitner et al 2016", "Racial bias is associated with ingroup death rate for Blacks and Whites: Insights from Project Implicit", null, "https://doi.org/10.1016/j.socscimed.2016.10.007"],
                                // ["Mounk 2014", "The Age of Responsibility: On the Role of Choice, Luck and Personal Responsibility in Contemporary Politics and Philosophy", null, "https://dash.harvard.edu/handle/1/14226053"],
                                ["Hook & Markus 2020", "Health in the United States: Are Appeals to Choice and Personal Responsibility Making Americans Sick?", null, "https://doi.org/10.1177/1745691619896252"],
                                ["Braveman & Dominguez 2021", "Abandon 'Race.' Focus on Racism.", null, "https://doi.org/10.3389/fpubh.2021.689462"],
                                ["Gollust & Lynch 2011", "Who Deserves Health Care? The Effects of Causal Attributions and Group Cues on Public Attitudes About Responsibility for Health Care Costs", null, "https://doi.org/10.1215/03616878-1460578"],
                                ["Hoffman et al 2016", "Racial bias in pain assessment and treatment recommendations, and false beliefs about biological differences between blacks and whites", null, "https://doi.org/10.1073%2Fpnas.1516047113"],
                                // ["Horne et al 2019", "Explanation as a Cognitive Process", null, "https://doi.org/10.1016/j.tics.2018.12.004"],

                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>

                <SectionContainer
                    overline={"Silliness is contagious."}
                    id={"conformity"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Visualizing social norms <br className={classes.hideXSDown}/>helps them spread.
                            </Header4>
                            <div className={classes.spacerQuarter}/>
                            <Body1>
                                We tend to match our attitudes, beliefs, behaviors, and values with the people around us.
                                So when charts show outcomes for certain identity groups, we can expect viewers from those
                                same groups to conform toward the norms of their visualized peers.
                            </Body1>

                            <br/>
                            <ReferenceList referenceList={[
                                ["Holder 2023", "Through a Partisan Lens: How Politics Overrides Information.", null, "https://3iap.com/political-psychology-primer-for-information-designers-Ol4B1UakTqi42tjIS4l_Vw/"],
                                ["Holder & Xiong 2023", "Polarizing Political Polls: How Visualization Design Choices Can Shape Public Opinion and Increase Political Polarization\n", null, "https://arxiv.org/abs/2309.00690"],
                                ["Holder 2021", "Radical Dots Simulator: Visualizing Agent Belief Change", null, "https://3iap.com/radical-dots-simulator-social-judgement-attitude-change-RdrhC8fTRHGbZCvj15ELbQ/"],
                                ["Allcott & Mullainathan 2010", " Behavior and energy policy", null, "https://doi.org/10.1126/science.1180775"],
                                ["Cialdini et al 2006", "Managing social norms for persuasive impact", null, "https://doi.org/10.1080/15534510500181459"],
                                ["Sherif & Hovland 1961", "Social judgment: Assimilation and contrast effects in communication and attitude change.", null, "https://archive.org/details/socialjudgmentas0000sher"],

                                // ["XXX", "XXX", null, "XXX"],
                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>


                <SectionContainer
                    overline={"Silliness comes from within."}
                    id={"inkblot"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Charts are windows. <br className={classes.hideXSDown}/>Charts are mirrors.
                            </Header4>
                            <div className={classes.spacerQuarter}/>
                            <Body1>
                                Instead of revealing new insights about the outside world,
                                confirmation biases mean that ambiguous data can be
                                co-opted as evidence to support the things we already believe.
                            </Body1>

                            <br/>
                            <ReferenceList referenceList={[
                                ["Luo & Zhao 2019", "Motivated Attention in Climate Change Perception and Action", null, "https://doi.org/10.3389/fpsyg.2019.01541"],
                                ["Xiong Bearfield et al 2023", "Same Data, Diverging Perspectives: The Power of Visualizations to Elicit Competing Interpretations", null, "https://arxiv.org/abs/2401.09289"],
                                ["Wang et al 2024", "Causal Priors and Their Influence on Judgements of Causality in Visualized Data", null, "https://arxiv.org/abs/2408.16077"],
                                ["Nickerson 1998", "Confirmation Bias: A Ubiquitous Phenomenon in Many Guises", null, "https://doi.org/10.1037/1089-2680.2.2.175"],
                                ["Stagnaro et al 2023", "No association between numerical ability and politically motivated reasoning in a large US probability sample", null, "https://doi.org/10.1073/pnas.2301491120"],

                                // ["XXX", "XXX", null, "XXX"],
                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>


                <SectionContainer
                    overline={"Silliness is a design choice."}
                    id={"responsibility"}
                    rowGap={MAIN_CONTENT_ROW_GAP}
                >
                    <GridContainer>
                        <GridColMainContent>
                            <Header4>
                                Your designs can help, <br className={classes.hideXSDown}/>or they can hurt.
                            </Header4>
                            <div className={classes.spacerQuarter}/>
                            <Body1>
                                It’s possible to design charts that are less toxic, and still effective for their original communication goals.
                                Data designers have a choice, and a responsibility, in how they visualize people.
                            </Body1>

                            <br/>
                            <ReferenceList referenceList={[
                                ["Jhangiani & Tarry 2022", "Social Categorization and Stereotyping", null, "https://opentextbc.ca/socialpsychology/chapter/social-categorization-and-stereotyping/"],
                                ["Wilmer & Kerns 2022", "What’s really wrong with bar graphs of mean values: variable and inaccurate communication of evidence on three key dimensions", null, "https://osf.io/preprints/osf/av5ey"],
                                ["Holder & Xiong 2022", "Dispersion vs Disparity: Hiding Variability Can Encourage Stereotyping When Visualizing Social Outcomes", null, "https://arxiv.org/abs/2208.04440"],
                                ["Holder & Padilla 2024", "Must Be a Tuesday: Affect, Attribution, and Geographic Variability in Equity-Oriented Visualizations of Population Health Disparities", null, "https://osf.io/c28wg"],

                                // ["XXX", "XXX", null, "XXX"],

                            ]}/>

                        </GridColMainContent>

                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>
                <div className={classes.spacer}/>

                <GridContainer>
                    <GridColLeftContent/>
                    <GridColMainContent>
                        <Header3>Related Material</Header3>
                    </GridColMainContent>
                </GridContainer>

                <div className={classes.spacerHalf}/>
                <SectionContainer title={"Talks and Workshops"}>
                    <GridContainer style={{rowGap: 48}}>
                        <GridColQuarterContent>
                            <Header7>Equitable Epidemiology</Header7>
                            <br/>
                            <img style={{width: "100%"}}
                                 src={"https://3iap.com/cdn/images/3iap-eqep-head-hor-v03.png"}/>

                            <br/><br/>


                            <ReferenceItem
                                // overlineLabel={"3iap Workshop"}
                                url={"/workshops/equitable-epidemiology-population-health-dataviz-training/"}
                                linkText={"Equitable Epidemiology: Deep Dive Workshops for Public Health Communicators"}
                                blurb={"In these deep-dive workshops, we’ll seek to motivate an elevated duty-of-care for public health data communication, cover techniques for identifying prevalent risks in messaging, and propose alternative, evidence-backed chart choices for common use cases for visualizing population health outcomes."}
                            />
                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            <Header7>Visual Villainy</Header7>
                            <br/>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/rlNy3Z40Lp0"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/>


                            <ReferenceItem
                                // overlineLabel={"Outlier"}
                                url={"https://www.youtube.com/watch?v=rlNy3Z40Lp0"}
                                linkText={"Visual Villainy: How to Undermine Humanity with Dataviz"}
                                blurb={"Outlier keynote on design considerations for ethical data visualization."}
                            />
                        </GridColQuarterContent>

                        <GridColQuarterContent>
                            <Header7>Chart Chat</Header7>
                            <br/>
                            <div className={classes.videoWrapper}>
                                <iframe className={classes.video}
                                        src="https://www.youtube.com/embed/kjHhRx8hmAI"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                            <br/>

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.youtube.com/watch?v=kjHhRx8hmAI"}
                                linkText={"Chart Chat 53 with Eli Holder"}
                                blurb={"Outlier keynote on design considerations for ethical data visualization."}
                            >
                                Chart Chat hosts Steve Wexler, Jeff Shaffer, Amanda Makulec, and Andy Cotgreave are
                                joined by Eli Holder, principal at 3 is a Pattern and researcher who has dug into some
                                sticky topics around how we represent (or misrepresent) information through
                                visualization.
                            </ReferenceItem>
                        </GridColQuarterContent>
                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>


                <SectionContainer title={"Miscellaneous Related Writing"}>
                    <GridContainer>


                        <GridColQuarterContent>

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/sketchy-bar-charts/"}
                                linkText={"Sketching Sketchy Bar Charts"}
                                blurb={"Understanding how viewers underestimate variability when viewing bar charts of averages."}
                            />

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/health-risk-data-contrast-effects/"}
                                linkText={"When is eleven scarier than twelve?"}
                                blurb={"Understanding perceptions of relative health risk."}
                            />

                            <ReferenceItem
                                // overlineLabel={"XXX"}
                                url={"https://www.effaff.com/read-the-room-ensemble-effect/"}
                                linkText={"Read the room."}
                                blurb={"The 'ensemble effect' helps explain why Jitter Plots feel so intuitive."}
                            />


                        </GridColQuarterContent>


                    </GridContainer>
                </SectionContainer>

                <div className={classes.spacer}/>


            </LayoutInner>


        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
